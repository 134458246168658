<template>
  <v-container fluid class="ma-0 pt-0 pb-0">
    <v-form ref="form" lazy-validation>
      <v-row dense class="px-2 pt-4 pb-0" align="center">
        <v-col>
          <v-text-field
            label="Group Name"
            v-model="promoItemGroup.name"
            :rules="[v => !!v || 'Group name is required']"
            dense
            :disabled="readOnlyPromo"
            validate-on-blur
            outlined>
          </v-text-field>
        </v-col>
        <v-col
          v-for="(field, i) in currentFields"
          :key="i">
          <v-checkbox
            v-if="field.isBoolean"
            v-model="promoItemGroup[`${field.value}`]"
            :label="field.name"
            hide-details="auto"
            class="my-auto"
            dense
            @change="setGetItemAllowance(promoItemGroup)">
          </v-checkbox>
          <v-text-field
            v-else-if="field.isInteger"
            v-model="promoItemGroup[`${field.value}`]"
            :label="field.name"
            type="number"
            min="0"
            step="1"
            :suffix="field.suffix"
            background-color="#fff"
            dense
            :disabled="readOnlyPromo"
            autocomplete="off"
            outlined>
          </v-text-field>
          <v-text-field
            v-else-if="field.isPercent"
            v-model="promoItemGroup[`${field.value}`]"
            :label="field.name"
            type="number"
            min="0"
            step="1"
            suffix="%"
            :rules="[isValidPercent]"
            background-color="#fff"
            dense
            :disabled="readOnlyPromo"
            autocomplete="off"
            outlined>
          </v-text-field>
          <v-text-field
            v-else
            v-model="promoItemGroup[`${field.value}`]"
            :label="field.name"
            :disabled="overrideGroupValues || readOnlyPromo"
            type="number"
            min="0"
            step=".01"
            prefix="$"
            :rules="field.value === 'promotion_price' ? promoPriceRules : getPriceRules(field)"
            :error="showErrorState(field, promoItemGroup)"
            background-color="#fff"
            dense
            autocomplete="off"
            outlined>
          </v-text-field>
        </v-col>
        <v-col v-if="isAccrualPromo">
          <v-select
            v-model="promoItemGroup.allowance_amt_type"
            label="Allowance Type"
            :items="accrualAllowanceTypes"
            :rules="[(v) => !!v || 'Please select an allowance type']"
            :menu-props="{ offsetY: true }"
            outlined
            :disabled="readOnlyPromo"
            dense>
          </v-select>
        </v-col>
        <v-col cols="auto" class="ml-auto mb-auto">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading || readOnlyPromo"
                fab
                small
                v-on="on"
                elevation="2"
                color="green"
                class="ml-2 white--text"
                @click="saveGroup">
                <v-icon dense>
                  {{ newGroup ? ' mdi-content-save-outline' : 'mdi-update' }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ newGroup ? 'Create' : 'Update' }} Group</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
// API
import PromoItemGroup from '@/axios/promotion-item-group-endpoint'
// mixins
import { formValidation } from '@/mixins/form-validation'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      loading: false,
      promoItemGroup: {
        name: 'Default',
        buy_item: true,
        get_item: false,
        qty: 1,
        retail_price: null,
        amount_off: null,
        percent_off: null,
        promotion_price: null,
        rebate_amount: null,
        variance: null,
        accrual_amount: null
      },
      billbackError: false,
      scanError: false,
      accrualAllowanceTypes: ['Case', 'Percent', 'Per Pound']
    }
  },
  name: 'ItemGroupEdit',
  mixins: [formValidation, displayAlert, utils, userAccess],
  props: {
    itemGroup: Object,
    promo_type_fields: Array,
    promo_type_constant: String,
    promo: Object,
    readOnlyPromo: {
      type: Boolean,
    }
  },
  created () {
    if (this.itemGroup) {
      this.promoItemGroup = JSON.parse(JSON.stringify(this.itemGroup))
    }
  },
  beforeDestroy () {
    this.resetAlert()
  },
  computed: {
    fields () {
      return [
        { name: 'Buy Item', value: 'buy_item', isBoolean: true, show: true },
        { name: 'Get Item', value: 'get_item', isBoolean: true, show: true },
        { name: 'Quantity', value: 'qty', isInteger: true, show: true },
        { name: 'Regular Retail', value: 'retail_price', show: true },
        // { name: 'Amount Off', value: 'amount_off' },
        { name: 'Percent Off', value: 'percent_off', isPercent: true, show: true },
        { name: 'Promotion Price', value: 'promotion_price', show: true },
        { name: this.tenant === 'pricechopper' && ['BUY_X_SAVE_$Y', 'BUY_X_GET_X'].includes(this.promo_type_constant) ? 'Redemption Allowance' : 'Scan Allowance', value: 'rebate_amount', show: true },
        { name: 'Variance', value: 'variance', show: true },
        { name: 'AMAP Allowance', value: 'amap_allowance', show: true },
        // { name: 'Accrual Allowance', value: 'accrual_allowance', show: true },
        { name: 'Early Buy Allowance', value: 'early_buy_allowance', show: this.tenant === 'pricechopper' && this.isEBA }
      ].filter(field => field.show)
    },
    currentFields () {
      if (this.promo_type_fields?.length > 0) {
        const fields = this.fields.filter(field => {
          if (field.value === 'variance' && this.tenant === 'awg') {
            return false // exclude the variance field for AWG tenant
          }
          if (this.promo_type_fields.includes(field.value)) {
            if (field.value === 'amap_allowance') {
              return this.isAMAP
            }
            return true
          }
        //   if (this.tenant === 'alliance-retail-group' && this.promo.promo_category?.name === 'Ad Scan') {
        //   return (field.value === 'accrual_allowance')
        // }
        })
        if (this.promo_type_fields.includes('billback_allowance')) {
          fields.push(this.billbackAllowanceField)
        }
        return fields
      }
      return []
    },
    billbackAllowanceField () {
      if (this.isAccrualPromo) {
        const allowanceType = this.promoItemGroup.allowance_amt_type
        return {
          name: 'Billback Allowance',
          value: 'billback_allowance',
          isPercent: (allowanceType?.toLowerCase() === 'percent'),
          isInteger: (allowanceType?.toLowerCase() === 'per pound'),
          suffix: (allowanceType?.toLowerCase() === 'per pound') ? 'lbs' : undefined
        }
      }
      return { name: 'Billback Allowance', value: 'billback_allowance' }
    },
    newGroup () {
      return Boolean(!this.promoItemGroup?.id)
    },
    promoPriceRules () {
      return (this.isAccrualPromo)
        ? [this.isValidRequiredPrice]
        : [this.isValidPrice]
    },
    isGetItemGroup () {
      return this.promo_type_constant === "BUY_X_GET_X" && (this.promoItemGroup.get_item && !this.promoItemGroup.buy_item)
    },
    overrideGroupValues () {
      return this.isGetItemGroup && (this.$auth.tenant !== 'pricechopper')
    }
  },
  methods: {
    saveGroup () {
      let group = this.$config.nullifyEmptyStrings(this.promoItemGroup)
      const validGroup = this.validateGroup(group)
      if (!validGroup) return
      
      this.$config.objectStringsToFloat(group)
      if (this.isAccrualPromo) {
        group.allowance_amt = group.billback_allowance
      }
      return this.newGroup ? this.createGroup(group) : this.updateGroup(group)
    },
    async createGroup (group) {
      this.loading = true
      try {
        group.promo_id = this.promo.id
        const res = await PromoItemGroup.post(group)
        if (res?.data?.id) {
          group.id = res.data.id
          this.emitAlert(true, 'success', 'Promo item group details saved', [], true)
          this.$emit('saved', group)
          this.resetErrors()
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async updateGroup (group) {
      this.loading = true
      try {
        const { id } = group
        await PromoItemGroup.put(id, group)
        this.emitAlert(true, 'success', 'Promo item group details updated', [], true)
        this.$emit('saved', group)
        this.resetErrors()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    validateGroup (group) {
      if (this.isAccrualPromo) {
        return this.isValidAccrual(group)
      }
      const validFields = this.$refs.form.validate()
      if (!this.validPromoLevel) {
        const errors = []
        if (!this.hasScanAllowance(group) && !this.validScanPromo) {
          this.scanError = true
          errors.push('Scan allowance is required.')
        }
        if (!this.hasBillingAllowance(group) && !this.validBillbackPromo) {
          this.billbackError = true
          errors.push(this.billbackErrorMessage)
        }
        if (errors.length > 0) {
          this.emitAlert(true, 'warning', null, errors)
          return false
        }
      }
      if (!validFields) {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
        return false
      }
      return true
    },
    isValidAccrual (group) {
      const validFields = this.$refs.form.validate()
      const numericAllowance = this.getNumericPrice(group.billback_allowance)
      if (numericAllowance === 0) {
        this.billbackError = true
        this.emitAlert(true, 'warning', 'Billback allowance must have a value')
        return false
      }
      if (!validFields) {
        this.emitAlert(true, 'warning', 'Please check your form for errors')
        return false
      }
      return true
    },
    setGetItemAllowance (group) {
      if (this.overrideGroupValues) group.rebate_amount = '0.00'
    },
    resetErrors () {
      if (this.scanError) this.scanError = false
      if (this.billbackError) this.billbackError = false
    }
  }
}
</script>